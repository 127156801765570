





































































import { PageBase } from '@/core/models/shared';
import { Component } from 'vue-property-decorator';
import { RelatorioService } from "@/core/services/Financeiro";

@Component
export default class RelExtratoPagamento extends PageBase { 

    service = new RelatorioService();   
    item:any = []
    classificacao:any = []
    ParcelasClassificacaoes:any = []
    titulo = "Extrato de Pagamento"
    resumo = {};
    parcelas:any = [] 

    overlay: boolean = false;

        
    situacaoColor(item: any) {
         if(item.situacaoParcela == "Pago")
            return "chipTeal";

        else if(item.situacaoId == 1)
            return "chipBlue";
    } 
    
    mounted() {
        const filter:any = this.$route.query;
        this.overlay = true;
        

        if(filter.contratoId > 0){

            this.service.ListarReceitaDetalhada(filter.contratoId, filter.dataBaixaInicial, filter.dataBaixaFinal)
                .then(
                    res => {   
                        this.item = res.data.parcelas;
                        this.resumo = res.data;
                    }, 
                err => {  
                    if (!err.response) {
                        this.$swal("Aviso", "Não foi possível acessar a API", "error");
                    } else {
                        this.$swal("Aviso", err.response.data, "error");
                    }
                }).finally(() =>{ 
                    this.overlay = false;
                });
            }else{
                this.$swal("Aviso", 'É necessário selecionar o contrato', "error");
                this.item  = [];
                this.overlay = false;
            }
        } 
}
